@import '../var.css';

.container {
  width: 100%;
  height: 100%;
  background-color: var(--light);
  position: fixed;
  top: 0;
  right: 0;
  display: grid;
  place-items: center;
  opacity: 1;
  z-index: 5;
}
.container .typeList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.container .typeList li.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding: 2rem;
  background-color: var(--dark);
  color: var(--extra1);
  border-radius: 20px;
  position: relative;
}
.container .typeList li.item::before {
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border-radius: 15px;
  border: 2px solid var(--extra1);
}
.container .typeList li.item span {
  color: var(--light);
}

.container .btn {
  position: fixed;
  top: var(--height);
  right: 0;
  display: grid;
  place-items: center;
  background-color: var(--dark);
  height: var(--height);
  width: calc(var(--width));
  transition: all 0.2s ease-out;
  padding: var(--padding);
  opacity: 1;
  box-shadow: var(--box-shadow);
  z-index: 2;
}
.container .btn .btn_icon {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  transform: translateX(-50%);
  width: var(--width);
  height: var(--height);
  background-color: var(--dark);
  color: var(--extra1);
  border-radius: 50%;
  padding: var(--padding);
  transition: all 0.2s ease-out;
  box-shadow: var(--box-shadow);
}
.container .btn .btn_icon .icon {
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-out;
  transform: rotate(45deg);
}
