@import './var.css';

.App {
  text-align: center;
  min-height: 100%;
  height: auto;
  display: flex;
  background-color: var(--light);
}
input {
  outline: none;
  font: inherit;
}
a {
  text-decoration: none;
  color: inherit;
}
ul li {
  list-style-type: none;
}
