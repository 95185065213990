@import '../var.css';

.container {
  width: 100%;
  height: 100%;
  color: var(--dark);
}
.container article {
  margin: 2rem 2rem;
}
.container h1 {
  margin: 0;
  margin-bottom: 3rem;
  text-transform: uppercase;
  text-align: center;
  /* border-bottom: 1px dotted var(--extra1); */
  position: relative;
}
.container h1::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 1px;
  background-color: var(--extra1);
}
.container article p {
  text-align: left;
  line-height: 1.75;
}
.container article p span {
  font-weight: bold;
}
.container ul {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 2rem; */
}
.link {
  width: 60%;
  padding: 1rem 2rem;
  background-color: var(--dark);
  color: var(--extra1);
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* gap: 0.5rem; */
  margin: 1.25rem;
}

.link svg {
  margin-right: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
}
.underline {
  text-decoration: underline;
  text-decoration-color: var(--extra1);
}
