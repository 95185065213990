@import '../var.css';

.container {
  width: 100%;
  min-height: 100%;
  background-color: var(--light);
  display: flex;
}

.container .workout_name {
  position: fixed;
  top: calc(var(--width) * 2.5);
  right: var(--padding);
  color: var(--dark);
  letter-spacing: 3px;
  writing-mode: vertical-rl;
}
.container .body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.container .empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
