@import '../var.css';

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--light);
  z-index: 5;
}
.wrapper {
  height: 70%;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.header {
  font-weight: bold;
  color: var(--dark);
}
.body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.yes,
.no {
  position: relative;
  display: flex;
}
.yes::before {
  content: 'yes';
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--extra1);
}
.no::before {
  content: 'no';
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--extra1);
}
