@import '../var.css';

.btn {
  position: relative;
  background-color: var(--dark);
  color: var(--extra1);
  border-radius: 50%;
  display: grid;
  place-items: center;
  padding: calc(var(--padding) * 1);
  width: var(--width);
  height: var(--width);
}
.btn svg {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}
.btn .tooltip {
  position: absolute;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 50%;
  right: 120%;
  font-size: 0.7rem;
  line-height: 1rem;
  white-space: nowrap;
  color: var(--dark);
  font-weight: bold;
  pointer-events: none;
  text-transform: uppercase;
  transform: translateY(-50%);
}
