:root {
  --dark: #2e2c2f;
  --light: #f3e8ee;
  --extra1: #ff934f;
  --extra2: #7dcd85;
  --extra3: #a63a50;

  --font-family1: ;
  --font-family2: ;

  --width: 2rem;
  --height: var(--width);
  --padding: calc(var(--width) * 0.1);
  --box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.9);
}
@media (max-width: 760px) {
  :root {
    font-size: 17px;
  }
}
